module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Baltimore Corgis","short_name":"Baltimore Corgis","background_color":"hsl(264, 36%, 31%)","theme_color":"hsl(264, 36%, 31%)","cache_busting_mode":"query","crossOrigin":"use-credentials","display":"browser","icon":"src/images/favicons/icon.png","start_url":"/","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":"5b167552ee626494c94fc3ae8a7f968d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
